@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }

  &[id] {
    scroll-margin-top: var(--topbarHeight);
    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.root:first-of-type {
  min-height: 40vh;
}

section:first-of-type {
  display: grid;
  align-items: end;
}

section:first-of-type .sectionContent {
  padding: calc(32px + var(--topbarHeight)) 0 32px 0;

  @media (--viewportMedium) {
    padding: calc(64px + var(--topbarHeightDesktop)) 0 64px 0;
  }
}

.withTitleSection {
  padding-top: 12px;
}

.subsection h2 {
  font-size: 24px;
}
