@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: calc(80vh + var(--topbarHeight));
  display: flex !important;
  justify-content: center;
  align-items: center !important;

  @media (--viewportMedium) {
    min-height: calc(80vh + var(--topbarHeightDesktop));
  }
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  margin-top: 0 !important;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
  }
}

.ctaContainer a {
  @media (--viewportMedium) {
    flex-direction: row;

    min-width: 200px;

    text-align: center;
  }
}

.ctaContainer a:first-of-type {
  background-color: var(--marketplaceColorSecondary);

  &:hover {
    background-color: var(--marketplaceColorSecondaryDark);
  }
}

.ctaContainer a:last-of-type {
  background-color: var(--colorPrimaryButton);

  &:hover {
    background-color: var(--colorPrimaryButtonDark);
  }

  @media (--viewportMedium) {
    margin-left: 24px;
  }
}

.root > div:nth-of-type(2) {
  padding: calc(32px + var(--topbarHeight)) 0;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}
